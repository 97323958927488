
const accordion = document.querySelector("#accordion-simple");

[...document.querySelectorAll(".accordion")].map(accordion => {
    accordion.querySelector(".accordion__header").onclick = toggle;
    accordion.querySelector(".accordion__title").onclick = toggle;
})

function toggle(event){
    if(event.target.className === "accordion__title") {
        this.parentNode.parentNode.classList.toggle("accordion--open");
    }else{
        this.parentNode.classList.toggle("accordion--open");

    }
}
