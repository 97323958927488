const search = window.location.search;
const params = search
    ? search
          .split('?')[1]
          .split('&')
          .reduce(
              (obj, item) => ({
                  ...obj,
                  [item.split('=')[0]]: item.split('=')[1]
              }),
              {}
          )
    : {};

function importAll(resource) {
    return `<ul>
            ${resource.keys().reduce((template, item) => {
                const moduleName = item.split('./')[1].split('.html')[0];
                template += `<li><a href='?type=${moduleName}'>${moduleName}</a></li>`;
                return template;
            }, '')}
        </ul>`;
}

const sidebarContent = importAll(
    require.context('html-loader!./templates/components/', true, /\.html$/)
);

document.querySelector('#sidebar').innerHTML = sidebarContent;

const canvas = document.querySelector('#canvas');

if (params.type) {
    const componentPage = document.createElement('div');
    componentPage.innerHTML = require('html-loader?interpolate!./templates/components/' +
        params.type +
        '.html');

    const codes = componentPage.querySelectorAll('pre');
    codes.forEach(node => {
        const highlighted = require('prismjs').highlight(
            node.innerHTML,
            Prism.languages.markup,
            'html'
        );
        node.innerHTML = highlighted;
    });

    canvas.innerHTML = componentPage.innerHTML;

    try{ require('./scripts/' + params.type + '.js'); }catch(e){
        console.log('no files for ', params.type, ': ', e);
    };
}

if (params.page) {
    canvas.innerHTML = require('html-loader!./templates/pages/' +
        params.page +
        '.html');
}
