import flatpickr from 'flatpickr';
const inputs = [...document.querySelectorAll('.datefield-range-2')];

flatpickr(document.querySelector('input.datefield'));
flatpickr(document.querySelector('input.datefield-range'), { mode: 'range', dateFormat: 'd M Y' });
const range = flatpickr(document.querySelector('.flatpickr-range'), {
    mode: 'range',
    dateFormat: 'd M Y',
    onValueUpdate: function(selectedDates, dateStr) {
        if (dateStr) {
            const dates = dateStr.match(/(\d{2}\s\w{3}\s\d{4})/g);
            dates.map((date, i) => (inputs[i].value = date));
        }
    }
});

inputs.map((input, i) => {
    input.onfocus = e => range.open();
    input.onblur = e => {
        e.target.value = range.selectedDates[i] ? range.formatDate(range.selectedDates[i], 'd M Y') : '';
    };
});
