
window.onload = () => {
    document.querySelector("#dropdown-full .dropdown__display").onclick = function() {
        document.querySelector('#dropdown-item').style.display = document.querySelector('#dropdown-item').style.display === 'block' ? 'none' : 'block'
    }

    document.querySelector("#togglemode").onclick = function() {
        this.innerHTML = this.innerHTML === "Dark mode" ? "Light mode" : "Dark mode";
        document.querySelector("#dropdown-full").classList.toggle("dropdown--light");
    }
}
