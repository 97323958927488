const minilogo = require('../img/logo.svg');

window.onload = () => {
    const $ = element => document.querySelector(element);
    $nav = $('.navigator');
    $('#mini-logo').src = minilogo;
    $('#mini').onclick = function() {
        $nav.classList.toggle('navigator--mini');
    };

    $('#open').onclick = function() {
        $nav.classList.toggle('navigator--open');
    };
};
