
function importAll(resource) {
    return `
            ${resource.keys().reduce((template, item) => {
                const className = item.split('./')[1].split('.svg')[0];
                template += `
                <div class="icon-holder">
                    <div class="icon-holder__the-icon">
                    <i class="icon-${className}"></i>
                    </div>
                    <small class="icon-holder__description">.icon-${className}</small>
                </div>`;
                return template;
            }, '')}
        `;
}

const icons = importAll(
    require.context('file-loader!../icons/', true, /\.svg$/)
);

document.querySelector(".icon-grid").innerHTML = icons;