const toggler = document.querySelector('.button.button--primary');
const fixedmenu = document.querySelector('.fixed-menu');

toggler.onclick = function() {

    const layover = fixedmenu.querySelector(".fixed-menu__layover")
    
    const show = () => {
        layover.style.display='block';
        setTimeout(() => layover.style.opacity = 1, 50);
    }

    const hide = () => {
        layover.style.opacity = 0;
        setTimeout(() => layover.style.display = 'none', 300);
        fixedmenu.classList.remove('fixed-menu--open');
    }

    layover.onclick = () => {
        console.log('eita');
        hide(); 
    }

    fixedmenu.classList.toggle('fixed-menu--open');
    if(!fixedmenu.classList.contains('fixed-menu--open')) {
        hide();
    }else {
        show();
    }
}
