const upload = document.querySelector('.upload');
const dropzone = upload.querySelector(".upload__dropzone");
const canvas = document.querySelector('#canvas');


window.addEventListener("dragover",function(e){
    e = e || event;
    e.preventDefault();
  },false);
  window.addEventListener("drop",function(e){
    e = e || event;
    e.preventDefault();
  },false);


['dragleave', 'dragend', 'drop'].forEach(function(eventName) {
    upload.addEventListener(eventName, function(event) {
        dropzone.classList.remove('upload__dropzone--dragover');
    });
});

['dragover', 'dragenter'].forEach(function(event) {
    upload.addEventListener(event, function() {
        dropzone.classList.add('upload__dropzone--dragover');
    });
});

upload.addEventListener('drop', function(e) {
    e.preventDefault();
    e.stopPropagation();
    dropzone.classList.remove('upload__dropzone--dragover');
    const fileList = upload.querySelector(".upload__files");
    const files = (e.dataTransfer.files);

    [...files].forEach( ({name}) => {
        fileList.innerHTML += `<li>${name}</li>`;
    })
});