
function importAll(resource) {
    return `
            ${resource.keys().reduce((template, item) => {
                const className = item.split('./')[1].split('.svg')[0];
                template += `
                <span class="icon-label">
                    <i class="icon-label__icon icon-${className}"></i>
                    <span class="icon-label__label">.icon-${className}</span>
                </span>`;
                return template;
            }, '')}
        `;
}

const icons = importAll(
    require.context('file-loader!../icons/', true, /\.svg$/)
);


document.querySelector(".icon-grid").innerHTML = icons;